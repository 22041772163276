<template>
  <div id="contact">
    <div class="content-box">
      <div class="inner">
        <div class="title">{{ $t('menu.contactUs') }}</div>
        <p>{{ $t('contact.desc', { platform: $config.info.fullName }) }}</p>
        <div class="contact-box">
          <div class="main-box clearfix">
            <div class="left">
              <div class="box">
                <div class="type">Live Chat</div>
                <div class="content chat">
                  <div @click="showLiveChat" data-testid="showLiveChat">{{ $t('contact.chatNow') }}</div>
                </div>
              </div>
              <div class="box">
                <div class="type">Email</div>
                <div class="content">
                  {{ GLOBAL_CONTACT_EMAIL }}
                </div>
                <div class="operate" v-clipboard:copy="GLOBAL_CONTACT_EMAIL" v-clipboard:success="onCopy"
                  v-clipboard:error="onError" data-testid="copy">
                  <img src="@/assets/images/contactUs/copy.svg" alt="" />
                </div>
              </div>
              <div class="box">
                <div class="type">Phone</div>
                <div class="content">
                  {{ phone }}
                </div>
                <div class="operate" v-clipboard:copy="phone" v-clipboard:success="onCopy"
                  v-clipboard:error="onError" data-testid="copy">
                  <img src="@/assets/images/contactUs/copy.svg" alt="" />
                </div>
              </div>
              <div class="box follow">
                <div class="type follow">Follow Us</div>
                <SocialMediaNew></SocialMediaNew>
              </div>
            </div>
            <div class="right">
              <div class="name">{{ $t('contact.globalOffice') }}</div>
              <div class="office">
                <a :href="`https://${GLOBAL_DOMAIN_WEBSITE}`">
                  <img src="@/assets/images/contactUs/map.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialMediaNew from '@/components/SocialMediaNew';

export default {
  components: { SocialMediaNew },
  data() {
    const regulator = this.$store.state.common.regulator;
    return {
      phone: this.$config.info.getPhone(regulator),
    };
  },
  methods: {
    onCopy(e) {
      this.$message({
        message: this.$t('common.field.successful'),
        type: 'success'
      });
    },
    onError(e) {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/PUGContactUs.scss';
</style>
