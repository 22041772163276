<template>
    <div class="socialMedia">
      <div class="brand" v-for="(media, key, index) in socialMedia" :key="'social-' + key + index">
        <a :href="media.link" target="_blank"><img :alt="media.name" :src="media.img"/></a>
      </div>
    </div>
  </template>
  
  <script>
  import fbLogo from '@/assets/images/socialNew/facebook_logo.svg';
  import inLogo from '@/assets/images/socialNew/linkedin_logo.svg';
  import twitterLogo from '@/assets/images/socialNew/twitter_logo.svg';
  import igLogo from '@/assets/images/socialNew/instagram_logo.svg';
  import youtubeLogo from '@/assets/images/socialNew/youtube_logo.svg';
  import tiktokLogo from '@/assets/images/socialNew/tiktok_logo.svg';
  
  export default {
    name: 'SocialMediaNew',
    data() {
      return {
        socialMedia: [
          { name: 'Facebook', link: 'https://www.facebook.com/puprime', img: fbLogo },
          { name: 'Twitter', link: 'https://twitter.com/puprime', img: twitterLogo },
          { name: 'Linked In', link: 'https://www.linkedin.com/company/puprime/', img: inLogo },
          {
            name: 'Instagram',
            link: 'https://www.instagram.com/puprime_official/',
            img: igLogo
          },
          {
            name: 'Youtube',
            link: 'https://www.youtube.com/channel/UCmhvCX6JOPiHvfnveFKmWxA/videos',
            img: youtubeLogo
          },
          { name: 'Tiktok', link: 'https://www.tiktok.com/@puprime', img: tiktokLogo }
        ]
      };
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .socialMedia {
    display: flex;
    img {
      width: 35px;
      margin-right: 10px;
    }
  }
  </style>
  